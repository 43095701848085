/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, TablePagination, TableRow, Typography } from "@material-ui/core";
import { userActions } from "../../_actions";
import { styled } from '@mui/material/styles';
import { connect } from "react-redux";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Chart } from "react-google-charts";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';

function sumTimes(time1, time2) {
  // Helper function to parse a time string into hours and minutes
  function parseTime(timeStr) {
    const [hours, minutes] = timeStr.match(/\d+/g).map(Number);
    return { hours, minutes };
  }

  // Parse both time strings
  const parsedTime1 = parseTime(time1);
  const parsedTime2 = parseTime(time2);

  // Calculate total time in hours and minutes
  let totalHours = parsedTime1.hours + parsedTime2.hours;
  let totalMinutes = parsedTime1.minutes + parsedTime2.minutes;

  // Convert any excess minutes to hours
  if (totalMinutes >= 60) {
    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes = totalMinutes % 60;
  }

  // Return the result in the same format
  return `${totalHours} hrs ${totalMinutes} min`;
}
const data = [
  ["JobType", "Hours"],
  ["DM", 0],
  ["PM", 0],
];

const data2 = [
  ["UserType", "DM", "PM"],
  ["INTERNAL STAFF", 0, 0],
  ["CONTRACTORS", 0, 0],
];

const data4 = [
  ['Trades', 'DM', 'PM'],
  ['Trades', 0, 0],
]


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3dc2f7",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const Dashboard = ({ dispatch, users }) => {
  const [pieChart, setPieChartData] = useState(data)
  const [pieChart2, setPieChartData2] = useState(data)
  const [barChart, setBarChartData] = useState(data2)
  const [barChart2, setBarChartData2] = useState(data2)
  const [barChart3, setBarChartData3] = useState(data2)
  const [columChart, setColumChartData] = useState(data4)

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [page2, setPage2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(5);
  const [page3, setPage3] = React.useState(0);
  const [rowsPerPage3, setRowsPerPage3] = React.useState(5);
  const [tableData, setTableData] = useState([])
  const [tableData2, setTableData2] = useState([])
  const [tableData3, setTableData3] = useState([])



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };

  const handleChangePage3 = (event, newPage) => {
    setPage3(newPage);
  };

  const handleChangeRowsPerPage3 = (event) => {
    setRowsPerPage3(parseInt(event.target.value, 10));
    setPage3(0);
  };


  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(userActions.landlordStaticsData());
    dispatch(userActions.dashBoardDataLandLord())
    dispatch(userActions.TradeHoursContractorsJobsLandLord())
    dispatch(userActions.TotalHoursInternalStaffLandLord())
    dispatch(userActions.TotalHoursContractorsLandLord())
    dispatch(userActions.locationJobsLandLord())
    dispatch(userActions.companyContractorJobsLandLord())
    dispatch(userActions.companyInternalStaffJobsLandLord())

  }, [dispatch]);

  useEffect(() => {
    if (users?.dashboardData?.dashboardData) {
      setIsLoading(false)
      setData(users?.contractorData?.TotalHoursContractorsLandLord)
      setData2(users?.ISHoursData?.TotalHoursInternalStaffLandLord)
      setData3(users?.tradeHourData?.TradeHoursContractorsJobsLandLord)
      dataSaveBarChart(users?.dashboardData?.dashboardData)
      setTableData(users?.locationData?.locationJobsLandLord || [])
      setTableData2(users?.companyContractorJobsLandLord?.companyContractorJobsLandLord || [])
      setTableData3(users?.companyISContractorJobsLandLord?.companyInternalStaffJobsLandLord || [])

      console.log("companyISContractorJobsLandLord", users?.companyContractorJobsLandLord?.companyContractorJobsLandLord)

    }
  }, [users.dashboardData, users?.contractorData, users?.ISHoursData, users?.tradeHourData, users?.locationData, users?.companyContractorJobsLandLord, users?.companyISContractorJobsLandLord])

  function dataSaveBarChart(data) {
    const data1 = [["UserType", "DM", "PM"]]
    if (data?.TotalWorkOrders) {
      data1.push(["Internal Staff", ...Object.values(data?.TotalWorkOrders.internalStaff)])
      data1.push(["Contractor", ...Object.values(data?.TotalWorkOrders.contractor)])
      setBarChartData2(data1)
    }
    const data2 = [["UserType", "DM", "PM"]]
    if (data?.OpenedWorkOrders) {
      data2.push(["Internal Staff", ...Object.values(data?.OpenedWorkOrders.internalStaff)])
      data2.push(["Contractor", ...Object.values(data?.OpenedWorkOrders.contractor)])
      setBarChartData3(data2)
    }
    const data3 = [["UserType", "DM", "PM"]]
    if (data?.ClosedWorkOrders) {
      data3.push(["Internal Staff", ...Object.values(data?.ClosedWorkOrders.internalStaff)])
      data3.push(["Contractor", ...Object.values(data?.ClosedWorkOrders.contractor)])
      setBarChartData(data3)
    }
  }
  function setData(data) {
    const data1 = {
      jobType: "Hours",
      ...data
    }
    setPieChartData(Object.entries(data1))
  }
  function setData2(data) {
    const data1 = {
      jobType: "Hours",
      ...data
    }
    setPieChartData2(Object.entries(data1))
  }


  function setData3(data) {
    const data1 = [['Trades', 'DM', 'PM']];

    if (Array.isArray(data) && data.length > 0) {
      data1.push(...data.map(({ name, totalHours }) => [name, totalHours.DM, totalHours.PM]));


      console.log("data1_______________", data1);
      setColumChartData(data1);
    }
  }
  // Define your data and options outside the component
  const options = {
    title: "Total Hours - Contractors",
    titleTextStyle: {
      color: "gray",
      fontSize: 22,
      bold: true,
      alignment: 'center',
    },
    pieSliceText: "value",
    pieStartAngle: 30,
    tooltip: { trigger: "title" },
    slices: {
      0: { color: "#6bba60" },
      1: { color: "#63a3ce" },
    },
    legend: {
      position: 'bottom', // Position legend at the bottom
      alignment: 'center', // Center align legend
      shape: 'square', // Set legend shape to square
    }
  };

  const options6 = {
    title: "Total Hours - Internal Staff",
    titleTextStyle: {
      color: "gray",
      fontSize: 22,
      bold: true,
      alignment: 'center',
    },
    pieSliceText: "value",
    pieStartAngle: 30,
    tooltip: { trigger: "title" },
    slices: {
      0: { color: "#6bba60" },
      1: { color: "#63a3ce" },
    },
    legend: {
      position: 'bottom', // Position legend at the bottom
      alignment: 'center', // Center align legend
      shape: 'square', // Set legend shape to square
    }
  };

  const options2 = {
    title: "Allocation of Hours",
    titleTextStyle: {
      color: "gray",
      fontSize: 22,
      bold: true,
      alignment: 'center',
    },
    series: {
      0: { axis: 'DM', color: '#6bba60' },
      1: { axis: 'PM', color: '#63a3ce' }
    },
    axes: {
      y: {
        DM: { label: 'parsecs' },
        PM: { side: 'right', label: 'apparent magnitude' }
      }
    },
    legend: {
      position: 'bottom',
      alignment: 'center',
      shape: 'square',
    },
    hAxis: {
      title: "Trades",
      minValue: 0,
    },
    vAxis: {
      title: "Hours",
    },
  };



  const options3 = {
    title: "Total Work Orders",
    titleTextStyle: {
      color: "gray",
      fontSize: 22,
      bold: true,
      alignment: 'center',
    },
    series: {
      0: { axis: 'DM', color: '#6bba60' },
      1: { axis: 'PM', color: '#63a3ce' }
    },
    legend: {
      position: 'bottom',
      alignment: 'center',
      shape: 'square',
    },
    chartArea: { width: "50%" },
    hAxis: {
      title: "Amount",
      minValue: 0,
    },
    vAxis: {
      title: "Personell Type",
    },
  };
  const options4 = {
    title: "Opened Work Orders",
    titleTextStyle: {
      color: "gray",
      fontSize: 22,
      bold: true,
      alignment: 'center',
    },
    series: {
      0: { axis: 'DM', color: '#6bba60' },
      1: { axis: 'PM', color: '#63a3ce' }
    },
    legend: {
      position: 'bottom',
      alignment: 'center',
      shape: 'square',
    },
    chartArea: { width: "50%" },
    hAxis: {
      title: "Amount",
      minValue: 0,
    },
    vAxis: {
      title: "Personell Type",
    },
  };

  const options5 = {
    title: "Closed Work Orders",
    titleTextStyle: {
      color: "gray",
      fontSize: 22,
      bold: true,
      alignment: 'center',
    },
    series: {
      0: { axis: 'DM', color: '#6bba60' },
      1: { axis: 'PM', color: '#63a3ce' }
    },
    legend: {
      position: 'bottom',
      alignment: 'center',
      shape: 'square',
    },
    chartArea: { width: "50%" },
    hAxis: {
      title: "Amount",
      minValue: 0,
    },
    vAxis: {
      title: "Personell Type",
    },
  };


  const renderStaticsData = () => {
    const { landlordStatsData } = users;

    console.log("ccccccccccccccccccccccc", users)
    if (landlordStatsData
    ) {
      return (
        <>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Widget
              title="Total Contractors"
              upperTitle
              disableWidgetMenu={false}
            >
              <div>
                <Typography size="xl" weight="medium">
                  {landlordStatsData.contractor || 0}
                </Typography>
              </div>
            </Widget>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Widget
              title="Total Internal Staff"
              upperTitle
              disableWidgetMenu={false}
            >
              <div>
                <Typography size="xl" weight="medium">
                  {landlordStatsData.internalStaff || 0}
                </Typography>
              </div>
            </Widget>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div>
              <Chart
                chartType="PieChart"
                data={pieChart}
                options={options}
                width={"100%"}
                height={"400px"}
              />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div>
              <Chart
                chartType="ColumnChart"
                data={columChart}
                options={options2}
                width={"100%"}
                height={"400px"}
              />
            </div>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div>
              <Chart
                chartType="PieChart"
                data={pieChart2}
                options={options6}
                width={"100%"}
                height={"400px"}
              />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Chart
              chartType="BarChart"
              width="100%"
              height="400px"
              data={barChart2}
              options={options3}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Chart
              chartType="BarChart"
              width="100%"
              height="400px"
              data={barChart3}
              options={options4}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Chart
              chartType="BarChart"
              width="100%"
              height="400px"
              data={barChart}
              options={options5}
            />
          </Grid>
          <TableContainer component={Paper} sx={{ width: '90%', overflow: 'hidden', margin: "auto", marginTop: 2 }} size="small" aria-label="a dense table" >
            <Table sx={{ minWidth: 700 }} aria-label="sticky table">
              <TableHead sx={{ backgroundColor: "#63a3ce", width: "900px" }}>
                <TableRow>
                  <TableCell align="center" colSpan={12}>
                    Total Work Orders Per Location
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead sx={{ backgroundColor: "#6bba60" }}>
                <TableRow sx={{ backgroundColor: "#6bba60" }}>
                  <StyledTableCell align="center">No.</StyledTableCell>
                  <StyledTableCell align="center">Location</StyledTableCell>
                  <StyledTableCell align="center">DM</StyledTableCell>
                  <StyledTableCell align="center">PM</StyledTableCell>
                  <StyledTableCell align="center">Total</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.location}</StyledTableCell>
                    <StyledTableCell align="center">{row.DM}</StyledTableCell>
                    <StyledTableCell align="center">{row.PM}</StyledTableCell>
                    <StyledTableCell align="center">{row.Total}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>

          <TableContainer component={Paper} sx={{ marginX: 10, marginY: 3 }}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead sx={{ backgroundColor: "#63a3ce", width: "900px" }}>
                <TableRow>
                  <TableCell align="center" colSpan={12}>
                    Work Orders Assigned - Per Company
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell align="center">No</TableCell>
                  <TableCell align="center">Contractor</TableCell>
                  <TableCell align="center">PM</TableCell>
                  <TableCell align="center">DM</TableCell>
                  <TableCell align="center">Total</TableCell>
                  <TableCell align="center">PM Hours</TableCell>
                  <TableCell align="center">DM Hours</TableCell>
                  <TableCell align="center">Total Hours</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData2.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2).map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.firstName + " " + row.lastName}</StyledTableCell>
                    <StyledTableCell align="center">{row.pmJob}</StyledTableCell>
                    <StyledTableCell align="center">{row.dmJob}</StyledTableCell>
                    <StyledTableCell align="center">{row.pmJob + row.dmJob}</StyledTableCell>
                    <StyledTableCell align="center">{row.timePM}</StyledTableCell>
                    <StyledTableCell align="center">{row.timeDM}</StyledTableCell>
                    <StyledTableCell align="center">{row.timeDM + row.timePM}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={tableData2.length}
              rowsPerPage={rowsPerPage2}
              page={page2}
              onPageChange={handleChangePage2}
              onRowsPerPageChange={handleChangeRowsPerPage2}
            />
          </TableContainer>
          <TableContainer component={Paper} sx={{ marginX: 10, marginY: 3 }}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead sx={{ backgroundColor: "#63a3ce", width: "900px" }}>
                <TableRow>
                  <TableCell align="center" colSpan={12}>
                    Work Orders Assigned - Per Internal Staff
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell align="center" >No.</TableCell>
                  <TableCell align="center">Employee Name</TableCell>
                  <TableCell align="center">PM</TableCell>
                  <TableCell align="center">DM</TableCell>
                  <TableCell align="center">Total</TableCell>
                  <TableCell align="center">PM Hours</TableCell>
                  <TableCell align="center">DM Hours</TableCell>
                  <TableCell align="center">Total Hours</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData3.slice(page3 * rowsPerPage3, page3 * rowsPerPage3 + rowsPerPage3).map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.firstName + " " + row.lastName}</StyledTableCell>
                    <StyledTableCell align="center">{row.pmJob}</StyledTableCell>
                    <StyledTableCell align="center">{row.dmJob}</StyledTableCell>
                    <StyledTableCell align="center">{row.pmJob + row.dmJob}</StyledTableCell>
                    <StyledTableCell align="center">{row.timePM}</StyledTableCell>
                    <StyledTableCell align="center">{row.timeDM}</StyledTableCell>
                    <StyledTableCell align="center">{sumTimes(row.timeDM, row.timePM)}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={tableData3.length}
              rowsPerPage={rowsPerPage3}
              page={page3}
              onPageChange={handleChangePage3}
              onRowsPerPageChange={handleChangeRowsPerPage3}
            />
          </TableContainer>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <PageTitle title="Dashboard" />
      <Grid container spacing={4}>
        {renderStaticsData()}
      </Grid>
    </>
  );
};

Dashboard.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps)(Dashboard);
